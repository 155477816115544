import React, { useState } from 'react';

const faqs = [
    {
        question: "What products does Rainfire offer in India?",
        answer: "Rainfire specializes in high-quality trending products in India, including mobile accessories, laptop accessories, and more. Their offerings encompass phone cases, chargers, screen protectors, laptop bags, and a variety of other accessories. Visit their website to explore their premium product catalog."
    },
    {
        question: "Does Rainfire offer free shipping in India?",
        answer: "Yes, we provide free standard shipping on all orders within India, with no minimum purchase required."
    },
    {
        question: "What is Rainfire's return policy in India?",
        answer: "In India, you can return any product within 7 days of placing your order for a full refund or exchange, provided it meets our return criteria. Please review our Return Policy for complete instructions on initiating a return."
    },
    {
        question: "How can I track my order in India?",
        answer: "You can easily track your order in India by visiting the 'Track Order' page on our website. Simply enter your Order ID to get real-time updates on the status and location of your shipment."
    },
    {
        question: "Does Rainfire offer warranty on its products in India?",
        answer: "Warranty coverage varies by product and will be clearly mentioned in the product details. Please refer to the product description and packaging for warranty information. If you have specific questions about a product's warranty, feel free to contact our customer support team for assistance."
    },
    {
        question: "What are the accepted payment methods in India?",
        answer: "We accept various payment methods in India, including credit/debit cards, net banking, UPI. You can find the full list of accepted payment methods during the checkout process."
    },
    {
        question: "How can I contact Rainfire's customer support in India?",
        answer: "You can reach our friendly customer support team in India by clicking on the 'Contact Us' link on our website. We are available to assist you with any questions, concerns, or inquiries you may have about our products, orders, or any other related matters."
    },
    // Add more FAQs as needed
];

const FAQs = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const toggleAccordion = (index) => {
        setOpenIndex(index);
    };

    return (
        <div className="container max-w-4xl mx-auto px-4 lg:px-0">
            <h1 className="text-3xl font-semibold text-center mb-6">Frequently Asked Questions</h1>
            {faqs.map((faq, index) => (
                <div key={index} className="border border-gray-300 rounded-lg p-4 mb-4">
                    <div
                        onClick={() => toggleAccordion(index)} // Attach the toggleAccordion to this div
                        className="cursor-pointer" // Add this className to make it look clickable
                    >
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-medium">{faq.question}</h2>
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="text-gray-600 hover:text-gray-800 transition duration-300 ease-in-out focus:outline-none"
                                aria-expanded={openIndex === index}
                            >
                                <span className="sr-only">{openIndex === index ? 'Collapse' : 'Expand'}</span>
                                <svg
                                    className={`w-4 h-4 transition-transform duration-300 ease-in-out ${openIndex === index ? 'transform rotate-180' : 'transform rotate-0'
                                        }`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d={openIndex === index ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div
                            className={`mt-2 text-gray-700 ${openIndex === index ? 'block' : 'hidden'}`}
                        >
                            {faq.answer}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQs;
