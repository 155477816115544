import React, { useState, useEffect } from 'react';
import { useCart } from '../contexts/CartProvider';
import { useProductData } from '../contexts/ProductDataContextProvider'; // Import the useProductData hook
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const Cart = () => {
    // Define dynamic SEO data for the Cart page
    const seoElements = {
        title: 'Your Cart',
        description: "View and manage items in your Rainfire shopping cart. Add, remove, or update products before proceeding to checkout.",
        canonicalUrl: 'https://www.rainfire.in/cart'
    };


    const navigate = useNavigate();
    const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
    const { products } = useProductData(); // Get products using useProductData hook
    const [cartProducts, setCartProducts] = useState([]); // State to hold products in the cart
    const [totalPrice, setTotalPrice] = useState(0);
    const [customerInfo, setCustomerInfo] = useState({
        fullName: '',
        email: '',
        phone: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    });

    const [inputErrors, setInputErrors] = useState({
        fullName: '',
        email: '',
        phone: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));

        // Clear the error message when user starts typing again
        setInputErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(phone);
    };

    useEffect(() => {
        // Filter products based on cart IDs and append quantity
        const filteredProducts = cart.map(cartItem => {
            const product = products.find(product => product.id === cartItem.productID);
            if (product) {
                return { ...product, quantity: cartItem.quantity };
            }
            return null;
        }).filter(Boolean);
        setCartProducts(filteredProducts);

        // Calculate total listPrice
        const listPrice = filteredProducts.reduce((acc, product) => acc + parseFloat(product.listPrice) * product.quantity, 0);
        setTotalPrice(listPrice);
    }, [cart, products]); // Add cart and products to dependency array

    const [paymentStatus, setPaymentStatus] = useState({
        isOpen: false, // To track whether the payment object is opened or not
        statusCode: null, // To track the status code of the payment
        message: '' // To track any message related to the payment
    });

    async function loadAndDisplayRazorpay() {
        try {
            // Check if all required fields are filled
            const requiredFields = ['fullName', 'email', 'phone', 'addressLine1', 'city', 'state', 'zipCode'];
            const errors = {};
            requiredFields.forEach(field => {
                if (!customerInfo[field]) {
                    errors[field] = 'This field is required!';
                }
            });

            // Check for email and phone validation errors
            if (customerInfo.email && !validateEmail(customerInfo.email)) {
                errors.email = 'Invalid email address!';
            }

            if (customerInfo.phone && !validatePhone(customerInfo.phone)) {
                errors.phone = 'Invalid phone number!';
            }

            if (Object.keys(errors).length === 0) {
                // Load Razorpay script
                const razorpayLoaded = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
                if (!razorpayLoaded) {
                    alert('Razorpay failed to load!!');
                    return;
                }

                setPaymentStatus(prevStatus => ({
                    ...prevStatus,
                    isOpen: true
                }));

                const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/createPayment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ cart })
                });

                if (!response.ok) {
                    throw new Error('Failed to create payment');
                }

                // Retrieve the order data
                const responseData = await response.json();

                const options = {
                    "key": process.env.REACT_APP_RZP_KEY_LIVE,
                    "amount": responseData.order.amount,
                    "currency": responseData.order.currency,
                    "name": "Rainfire",
                    "description": "",
                    "image": "https://firebasestorage.googleapis.com/v0/b/rainfire-webapp.appspot.com/o/assets%2Flogo.jpg?alt=media&token=98376859-300d-4775-b978-a2028f7efe2e",
                    "order_id": responseData.order.id,
                    "handler": function (response) {
                        saveOrder(response);
                    },
                    "prefill": {
                        "name": customerInfo.fullName,
                        "email": customerInfo.email,
                        "contact": customerInfo.phone
                    },
                    "notes": {
                        "address": customerInfo.addressLine1 + ' ' + customerInfo.addressLine2 + ' ' + customerInfo.city + ' ' + customerInfo.state + ' ' + customerInfo.zipCode
                    },
                    "theme": {
                        "color": "#000000"
                    }
                };

                // Create and open Razorpay payment object
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();

                paymentObject.on('payment.failed', function (response) {
                    setPaymentStatus({
                        isOpen: false,
                        statusCode: response.error.code,
                        message: response.error.description
                    });
                });
            } else {
                setInputErrors(errors);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function saveOrder(paymentResponse) {
        try {
            // Check if all required fields are filled
            const requiredFields = ['fullName', 'email', 'phone', 'addressLine1', 'city', 'state', 'zipCode'];
            const errors = {};
            requiredFields.forEach(field => {
                if (!customerInfo[field] || customerInfo[field].trim().length === 0) {
                    errors[field] = 'This field is required!';
                }
            });

            // Check for email and phone validation errors
            if (customerInfo.email && !validateEmail(customerInfo.email)) {
                errors.email = 'Invalid email address!';
            }

            if (customerInfo.phone && !validatePhone(customerInfo.phone)) {
                errors.phone = 'Invalid phone number!';
            }

            if (Object.keys(errors).length === 0) {
                setPaymentStatus(prevStatus => ({
                    ...prevStatus,
                    isOpen: true
                }));
                const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/saveOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...paymentResponse, orderedItems: cart, customerInfo })
                });

                const responseData = await response.json();

                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save order');
                }

                setPaymentStatus({
                    isOpen: false,
                    statusCode: 200,
                    message: 'Order placed successfully!'
                });

                clearCart();
            } else {
                setInputErrors(errors);
            }

        } catch (error) {
            console.error('Error:', error);
            setPaymentStatus({
                isOpen: false,
                statusCode: 500,
                message: 'Failed to save order'
            });
        }
    }

    function createSlug(productName) {
        // Replace spaces and consecutive hyphens with a single hyphen
        const cleanedName = productName
            .replace(/[^a-zA-Z0-9 -]/g, '') // Remove other special characters
            .replace(/ /g, '-')             // Replace spaces with hyphens
            .replace(/-+/g, '-');           // Replace consecutive hyphens with a single hyphen

        // Convert to lowercase and trim any leading/trailing hyphens
        return cleanedName.toLowerCase().replace(/^-+|-+$/g, '');
    }

    return (
        <>
            <SEO seoElements={seoElements} />
            <div className="container mx-auto p-4">
                {cart && cart.length < 1 && !paymentStatus.statusCode && (
                    <div className="text-stone-800 text-3xl text-center h-96 flex items-center justify-center font-bold">
                        <p>Your Cart is Empty <br /> Go Ahead And Start Shopping!</p>
                    </div>
                )}

                {cart && cart.length < 1 && paymentStatus.statusCode === 200 && (
                    <div className="p-6 md:mx-auto max-w-xl">
                        <svg viewBox="0 0 24 24" className="text-black w-16 h-16 mx-auto my-6">
                            <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
                        </svg>
                        <div className="text-center">
                            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{paymentStatus.message}</h3>
                            <p className="text-gray-600 my-2">Thank you for your order. Check your email for the order details.</p>
                            <p> Have a great day!  </p>
                            <div className="py-10 text-center">
                                <button className="px-12 bg-blue-600 hover:bg-blue-800 rounded-3xl text-white font-semibold py-3" onClick={() => { navigate('/') }}>
                                    GO BACK
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {cart && cart.length > 0 && (
                    <div className='w-full grid grid-cols-1 md:grid-cols-6 gap-4 h-fit'>
                        <div className='md:col-span-4'>
                            <h1 className="text-lg font-semibold text-stone-800 inline-flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z" clipRule="evenodd" />
                                </svg>
                                Shopping Cart
                            </h1>
                            <div className='mt-4 md:col-span-4 flex flex-col gap-6'>
                                {cartProducts.map((product) => (
                                    <div key={product.id} className="grid grid-cols-6 md:grid-cols-4 gap-4 shadow-lg p-4 rounded-xl">
                                        <div className='col-span-2 md:col-span-1 flex items-center justify-center cursor-pointer' onClick={() => { navigate(`/product-details/${createSlug(product.productName)}`) }}>
                                            <img src={product.featuredImage} className='w-full h-24 md:h-44 object-contain' alt="" />
                                        </div>
                                        <div className='col-span-4  md:col-span-3 flex flex-col gap-2'>
                                            <h2 className="text-xs xl:text-lg font-normal xl:font-semibold text-slate-900 cursor-pointer" onClick={() => { navigate(`/product-details/${createSlug(product.productName)}`) }}>{product.productName}</h2>
                                            <p className='text-sm hidden xl:block'>{product.shortDescription}</p>
                                            <p className='text-sm hidden md:block truncate'>{product.description}</p>
                                            <div className="flex justify-between items-center mt-2">
                                                <div className='inline-flex items-center gap-6'>
                                                    <span className="font-bold text-xl text-stone-800">₹ {parseFloat(product.listPrice).toLocaleString('en-IN')}</span>
                                                    <div className='w-20 grid grid-cols-3 gap-1 border rounded-lg overflow-hidden'>
                                                        <div className='w-full bg-slate-100 flex items-center justify-center cursor-pointer' onClick={() => { const q = parseInt(product.quantity); if (q > 1) updateQuantity(product.id, q - 1); }}>-</div>
                                                        <div className='text-center'>{product.quantity}</div>
                                                        <div className='w-full bg-slate-100 flex items-center justify-center cursor-pointer' onClick={() => { updateQuantity(product.id, parseInt(product.quantity) + 1) }}>+</div>
                                                    </div>
                                                </div>
                                                <svg onClick={() => removeFromCart(product.id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-600 cursor-pointer">
                                                    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='mx-auto text-gray-600 font-medium text-lg border rounded-lg px-4 py-2 cursor-pointer inline-flex items-center gap-2' onClick={() => { navigate('/store') }}>
                                    <img src="https://cdn.shopify.com/s/files/1/1305/2183/files/Group_19683.png?v=1688990755" className='w-6' alt="" />
                                    Continue Shopping
                                </div>
                            </div>
                        </div>
                        <div className='md:col-span-2 shadow-lg h-fit rounded-lg p-4'>
                            <h3 className='font-bold text-md text-stone-800 mb-4'>Order Summary</h3>
                            <table className="w-full mb-4">
                                <tbody>
                                    {cartProducts.map((product, index) => (
                                        <tr key={product.id} className='text-slate-700 text-xs'>
                                            <td className='py-1'>{index + 1}.</td>
                                            <td className='py-1'>{product.productName.length > 30 ? `${product.productName.slice(0, 30)}...` : product.productName}</td>
                                            <td className="text-right py-1">₹ {parseFloat(product.listPrice).toLocaleString('en-IN')}<sub> x {product.quantity}</sub></td>
                                        </tr>
                                    ))}
                                    <tr className='text-slate-700 text-sm'>
                                        <td className='py-1'></td>
                                        <td className='py-1'><div className='w-fit px-2 py-1 bg-green-200 text-green-600 font-medium text-xs rounded-sm'>Shipping Charges</div></td>
                                        <td className='font-bold text-right py-1'>₹ 0</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="font-bold py-1">Total</td>
                                        <td className="font-bold text-right py-1">₹ {parseFloat(totalPrice).toLocaleString('en-IN')}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3 className='font-bold text-xl text-stone-800 mb-4'>Shipping Details</h3>
                            <div className='grid grid-cols-2 gap-2 mb-4 overflow-hidden'>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Full Name' name="fullName" value={customerInfo.fullName} onChange={handleInputChange} />
                                    {inputErrors.fullName && <span className="text-red-500 text-xs">{inputErrors.fullName}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="email" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Email' name="email" value={customerInfo.email} onChange={handleInputChange} />
                                    {inputErrors.email && <span className="text-red-500 text-xs">{inputErrors.email}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Phone' name="phone" value={customerInfo.phone} onChange={handleInputChange} />
                                    {inputErrors.phone && <span className="text-red-500 text-xs">{inputErrors.phone}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Address Line 1' name="addressLine1" value={customerInfo.addressLine1} onChange={handleInputChange} />
                                    {inputErrors.addressLine1 && <span className="text-red-500 text-xs">{inputErrors.addressLine1}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Address Line 2' name="addressLine2" value={customerInfo.addressLine2} onChange={handleInputChange} />
                                    {inputErrors.addressLine2 && <span className="text-red-500 text-xs">{inputErrors.addressLine2}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='City' name="city" value={customerInfo.city} onChange={handleInputChange} />
                                    {inputErrors.city && <span className="text-red-500 text-xs">{inputErrors.city}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='State' name="state" value={customerInfo.state} onChange={handleInputChange} />
                                    {inputErrors.state && <span className="text-red-500 text-xs">{inputErrors.state}</span>}
                                </div>
                                <div className='w-full'>
                                    <input type="text" className='w-full px-2 py-3 border rounded-lg outline-none text-sm' placeholder='Zip Code' name="zipCode" value={customerInfo.zipCode} onChange={handleInputChange} />
                                    {inputErrors.zipCode && <span className="text-red-500 text-xs">{inputErrors.zipCode}</span>}
                                </div>
                            </div>
                            <button onClick={loadAndDisplayRazorpay} disabled={paymentStatus.isOpen} className={`mt-4 text-lg text-white bg-slate-800 font-bold p-3 sm:p-4 rounded-lg inline-flex items-center justify-between gap-2 w-full ${paymentStatus.isOpen ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                                Make Payment
                                {paymentStatus.isOpen ?
                                    <svg
                                        className="animate-spin h-5 w-5 border-t-2 border-white-600 rounded-full"
                                        viewBox="0 0 24 24">
                                    </svg> :
                                    <div className='inline-flex items-center gap-2'>
                                        <img src='https://cdn.gokwik.co/v4/images/upi-icons.svg' alt='' />
                                        <img src="https://cdn.gokwik.co/v4/images/right-arrow.svg" alt="" /></div>
                                }
                            </button>
                            <button className={`mt-4 text-lg border-2 border-slate-800 text-slate-800 font-bold p-3 sm:p-4 rounded-lg inline-flex items-center justify-between gap-2 w-full`} disabled={paymentStatus.isOpen} onClick={() => { saveOrder() }}>
                                Cash On Delivery
                                {paymentStatus.isOpen ?
                                    <svg
                                        className="animate-spin h-5 w-5 border-t-2 border-black rounded-full"
                                        viewBox="0 0 24 24">
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 w-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Cart;