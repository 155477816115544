import React from 'react';

const ProductReviews = ({ reviews }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {reviews.map((review, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-4">
                    <div className="flex flex-col">
                        <div className='flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rounded-full mr-2">
                                <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                            </svg>
                            <span className='inline-flex items-center gap-2'><h3 className="text-sm font-semibold text-stone-700">{review.userName}</h3><p className="text-gray-600 text-xs">{review.date}</p></span>
                        </div>

                        <div className="mt-2">
                            {renderStars(review.rating)}
                        </div>

                        <p className="text-gray-500 text-sm">{review.text}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span
                key={i}
                className={`text-green-600 ${i <= rating ? 'fill' : ''}`}
            >
                ★
            </span>
        );
    }
    return stars;
};

export default ProductReviews;
