import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth, signInWithEmailAndPassword as signInWithEmailAndPasswordFunc, signOut as signOutFunc } from '../firebase/firebaseFunctions';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [authStatus, setAuthStatus] = useState({ isLoading: true, authMethod: null });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setAuthStatus({ isLoading: true, authMethod: null });
      if (user) {
        try {
          const tokenResult = await user.getIdTokenResult();
          const { claims } = tokenResult;
          setCurrentUser({ ...user, userRole: claims.userRole });
        } catch (error) {
          console.error('Error fetching custom claims:', error);
        }
      } else {
        setCurrentUser(null);
      }
      setAuthStatus({ isLoading: false, authMethod: null });
    });

    return () => unsubscribe();
  }, []);

  const signInWithEmailAndPassword = useCallback(async (email, password) => {
    setAuthStatus({ isLoading: true, authMethod: 'Email' });
    try {
      const userCredential = await signInWithEmailAndPasswordFunc(auth, email, password);
      setAuthStatus({ isLoading: false, authMethod: null });
      return userCredential.user;
    } catch (error) {
      setAuthStatus({ isLoading: false, authMethod: null });
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    setAuthStatus({ isLoading: true, authMethod: 'Logout' });
    try {
      await signOutFunc(auth);
      setCurrentUser(null);
      setAuthStatus({ isLoading: false, authMethod: null });
    } catch (error) {
      setAuthStatus({ isLoading: false, authMethod: null });
      throw error;
    }
  }, []);

  const value = {
    currentUser,
    authStatus,
    signInWithEmailAndPassword,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}