import React from 'react';
import SEO from '../components/SEO.js';

const TermsAndConditions = () => {
  // Define dynamic SEO data for the Terms and Conditions page
  const seoElements = {
    title: 'Terms and Conditions - Rainfire',
    description: "Read Rainfire's terms and conditions. Understand the agreements and policies governing the use of our website and services.",
    canonicalUrl: 'https://www.rainfire.in/terms-and-conditions'
};

  return (
    <>
      <SEO seoElements={seoElements} />
      <div className="bg-gray-100 py-10">
        <div className="bg-white rounded-lg p-8 shadow-md container mx-auto">
          <h1 className="text-3xl font-semibold mb-4">Terms and Conditions</h1>
          <b className='text-stone-600'>Last updated May 02, 2024</b>
          <br /><br />

          <p className='text-sm text-stone-700'>
            Welcome to the Rainfire Terms and Conditions page. These terms and conditions outline the rules and regulations for the use of our website.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Acceptance of Terms</h2>
          <br />

          <p className='text-sm text-stone-700'>
            By accessing and using our website, you accept and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our website.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Use of the Website</h2>
          <br />
          <p className='text-sm text-stone-700'>
            You may use our website for your own personal, non-commercial use. You may not use the website for any unlawful or prohibited purposes.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>User Account</h2>
          <br />
          <p className='text-sm text-stone-700'>
            You may need to create a user account to access certain features on our website. You are responsible for maintaining the confidentiality of your account and password.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Privacy Policy</h2>
          <br />
          <p className='text-sm text-stone-700'>
            Your use of our website is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Changes to Terms</h2>
          <br />
          <p className='text-sm text-stone-700'>
            We may update or revise these Terms and Conditions at any time. Your continued use of the website after such changes constitutes your acceptance of the new Terms and Conditions.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Contact Us</h2>
          <br />
          <p className='text-sm text-stone-700'>
            If you have any questions or concerns about our Terms and Conditions, please contact us at <span onClick={() => { window.open('mailto:support@rainfire.in'); }} className='font-bold text-slate-900'>support@rainfire.in</span>.
          </p>
          <br />
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
