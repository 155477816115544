import React, { useState } from 'react';
import Lottie from 'lottie-react';
import trackOrderLottie from '../assets/trackOrderLottie.json'
import truckLottie from '../assets/truckLottie.json'
import SEO from '../components/SEO.js';
import { useParams } from 'react-router-dom';

export default function TrackOrder() {
    // Define dynamic SEO data for the Track Order page
    const seoElements = {
        title: 'Track Your Order',
        description: "Track your order with Rainfire. Enter your order number and email address to monitor the status and delivery of your purchase.",
        canonicalUrl: 'https://www.rainfire.in/track-order'
    };    

    const { trackingId = "" } = useParams();
    const [orderId, setOrderId] = useState(trackingId);
    const [orderDetails, setOrderDetails] = useState(null);
    const [error, setError] = useState(null);
    const [fetchingOrderDetails, setFetchingOrderDetails] = useState(false);

    const trackOrder = async () => {
        try {
            setError(null);
            setOrderDetails(null);
            if (!orderId) { setError('Please Enter a valid Order ID to track your order.'); return; }
            setFetchingOrderDetails(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/orderDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ orderId })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            const data = await response.json();
            setOrderDetails(data);
            setFetchingOrderDetails(false);
            setError(null);
        } catch (error) {
            setFetchingOrderDetails(false);
            setOrderDetails(null);
            setError(error.message || 'An error occurred');
        }
    };

    return (
        <>
            <SEO seoElements={seoElements} />
            <div className='w-full container mx-auto px-6 xl:px-0 grid grid-cols-1 lg:grid-cols-2'>
                <div className='order-2 lg:order-1'>
                    <Lottie animationData={truckLottie} />
                </div>
                <div className='order-1 lg:order-2 w-3/4 mt-4 lg:mt-0 lg:min-h-[650px] mx-auto flex items-center justify-center'>
                    <div>
                        <div className='flex flex-col gap-3'>
                            <h2 className='text-3xl font-bold'>Track Your Order</h2>
                            <p className='text-gray-500 text-xs'>Enter your order number to track your package</p>
                            <input type="text" className='w-[350px] border rounded px-2 py-3 text-sm' placeholder='Enter Order Id' value={orderId} onChange={(e) => { setOrderId(e.target.value) }} />
                            <button className='w-[350px] bg-black text-white font-normal rounded px-2 py-3 text-sm inline-flex items-center justify-center gap-4' onClick={trackOrder}>
                                Track Order
                                {fetchingOrderDetails ?
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div> :
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                        <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                    </svg>
                                }
                            </button>
                        </div>
                        {error && <div className="text-red-500 text-xs mt-4">{error}</div>}
                        {orderDetails && (
                            <div>
                                <hr className='mt-6' />
                                <div className='flex flex-wrap gap-4 justify-between mt-1'>
                                    <div className='font-medium text-stone-700 text-sm shrink-0 flex flex-col'>
                                        <span>Order ID</span>
                                        <span className='text-xs'>{orderDetails.orderId}</span>
                                    </div>
                                    <div className='font-medium text-stone-700 text-sm shrink-0 flex flex-col'>
                                        <span>Status</span>
                                        <span className='text-xs'>{orderDetails.orderStatusHistory[orderDetails.orderStatusHistory.length - 1].status}</span>
                                    </div>
                                    <div className='font-medium text-stone-700 text-sm shrink-0 flex flex-col'>
                                        <span>Placed On</span>
                                        <span className='text-xs'>{new Date(orderDetails.orderDateTime).toLocaleDateString('en-GB').replace(/\//g, '-')} {new Date(orderDetails.orderDateTime).toLocaleTimeString('en-GB')}</span>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-4 mt-6'>
                                    <div className='p-2 w-full min-h-28 border rounded font-normal text-stone-700 text-sm shrink-0 flex flex-col gap-2'>
                                        <span className='font-medium'>Shipping Address</span>
                                        <span className='text-gray-500 text-xs capitalize'>{orderDetails.customerInfo.fullName}</span>
                                        <span className='text-gray-500 text-xs capitalize'>{orderDetails.customerInfo.addressLine1}, {orderDetails.customerInfo.addressLine2}, {orderDetails.customerInfo.city}, {orderDetails.customerInfo.state}, {orderDetails.customerInfo.zipCode}</span>
                                    </div>
                                    <div className='p-2 w-full min-h-28 border rounded font-normal text-stone-700 text-sm shrink-0 flex flex-col gap-2'>
                                        <span className='font-medium'>Contact Information</span>
                                        <span className='text-gray-500 text-xs w-full truncate'>Email: {orderDetails.customerInfo.email}</span>
                                        <span className='text-gray-500 text-xs'>Phone: {orderDetails.customerInfo.phone}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='mt-6 w-full'>
                            <Lottie animationData={trackOrderLottie} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}