import React, { useState } from 'react'
import { useProductData } from '../../contexts/ProductDataContextProvider';
import { storage, ref, uploadBytes, getDownloadURL, v4 } from '../../firebase/firebaseFunctions';

const AddProducts = () => {
    const { refreshProductData } = useProductData();

    const [isSavingProduct, setIsSavingProduct] = useState(false);
    const [productToBeAdded, setProductToBeAdded] = useState({
        productName: '',
        category: '',
        listPrice: '',
        maximumRetailPrice: '',
        isFeatured: false,
        shortDescription: '',
        videoURL: '',
        flipkartLink: '',
        amazonLink: '',
        description: '',
        featuredImage: '',
        images: [],
        backgroundColor: 'bg-gray-100',
        stockAvailable: true,
    });

    const updateProductState = (event) => {
        const { name, value, type, checked, files } = event.target;

        setProductToBeAdded((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? (name === 'featuredImage' ? files[0] : Array.from(files)) : value,
        }));
    };

    const uploadFilesToStorage = async (files) => {
        const uploadedFileUrls = [];

        for (const file of files) {
            const fileId = v4();
            const fileRef = ref(storage, `product-images/${fileId}`);

            // Upload file to Firebase Storage
            await uploadBytes(fileRef, file);

            // Get download URL
            const downloadURL = await getDownloadURL(fileRef);

            uploadedFileUrls.push(downloadURL);
        }

        return uploadedFileUrls;
    };

    const addProduct = async (e) => {
        e.preventDefault();
        setIsSavingProduct(true);

        try {
            // Upload featured image and images to Firebase Storage
            const featuredImageURL = await uploadFilesToStorage([productToBeAdded.featuredImage]);
            const imagesURLs = await uploadFilesToStorage(productToBeAdded.images);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/addProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    productName: productToBeAdded.productName,
                    category: productToBeAdded.category,
                    listPrice: productToBeAdded.listPrice,
                    maximumRetailPrice: productToBeAdded.maximumRetailPrice,
                    isFeatured: productToBeAdded.isFeatured,
                    shortDescription: productToBeAdded.shortDescription,
                    videoURL: productToBeAdded.videoURL,
                    flipkartLink: productToBeAdded.flipkartLink,
                    amazonLink: productToBeAdded.amazonLink,
                    description: productToBeAdded.description,
                    backgroundColor: productToBeAdded.backgroundColor,
                    stockAvailable: productToBeAdded.stockAvailable,
                    featuredImage: featuredImageURL[0], // Send URL of featured image
                    images: imagesURLs, // Send URLs of additional images
                })
            });

            if (!response.ok) {
                throw new Error('Error adding product');
            }

            refreshProductData();
        } catch (error) {
            console.error('Error adding product:', error);
        } finally {
            setIsSavingProduct(false);
        }
    };

    return (
        <div>
            <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-700 mb-1">Add Product</h6>
            <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Grid Item 1 */}
                <div className='flex flex-col gap-6'>

                    <div className="flex flex-row items-center justify-center gap-4 shadow-md rounded-lg px-4 py-2">
                        <label htmlFor="featuredImage" className="h-24 w-24 border-2 p-2 rounded-lg flex items-center justify-center text-xs text-center">
                            {productToBeAdded.featuredImage ? '1 Selected' : 'Featured Image'}
                        </label>
                        <input
                            type="file"
                            name="featuredImage"
                            id="featuredImage"
                            className="outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent hidden"
                            onChange={updateProductState}
                        />
                        <label htmlFor="images" className="h-24 w-24 border-2 p-2 rounded-lg flex items-center justify-center text-xs text-center">
                            {productToBeAdded.images.length > 0 ? productToBeAdded.images.length + ' Selected' : 'Product Photos'}
                        </label>
                        <input
                            type="file"
                            name="images"
                            id="images"
                            className="outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent hidden"
                            onChange={updateProductState}
                            multiple
                        />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="productName" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Name</label>
                        <input type="text" name="productName" id="productName" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.productName} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="category" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Category</label>
                        <input type="text" name="category" id="category" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.category} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="listPrice" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>List Price (Rs.)</label>
                        <input type="number" name="listPrice" id="listPrice" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.listPrice} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="maximumRetailPrice" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>MRP (Rs.)</label>
                        <input type="number" name="maximumRetailPrice" id="maximumRetailPrice" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.maximumRetailPrice} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="isFeatured" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Is Featured</label>
                        <input type="checkbox" name="isFeatured" id="isFeatured" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' checked={productToBeAdded.isFeatured} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="shortDescription" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Short Description</label>
                        <textarea name="shortDescription" id="shortDescription" cols="30" rows="3" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.shortDescription} onChange={updateProductState} />
                    </div>
                </div>
                {/* Grid Item 2 */}
                <div className='flex flex-col gap-6'>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="videoURL" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Video URL</label>
                        <input type="text" name="videoURL" id="videoURL" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.videoURL} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="flipkartLink" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Flipkart Link</label>
                        <input type="text" name="flipkartLink" id="flipkartLink" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.flipkartLink} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="amazonLink" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Amazon Link</label>
                        <input type="text" name="amazonLink" id="amazonLink" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.amazonLink} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="description" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Description</label>
                        <textarea name="description" id="description" cols="30" rows="8" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeAdded.description} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="stockAvailable" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Stock Available</label>
                        <input type="checkbox" name="stockAvailable" id="stockAvailable" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' checked={productToBeAdded.stockAvailable} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="backgroundColor" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Background Color</label>
                        <input type="color" name="backgroundColor" id="backgroundColor" className="appearance-none bg-transparent border-none h-0 w-0 invisible" onChange={updateProductState} />
                        <span className={`h-12 w-12 rounded-full`} style={{ backgroundColor: productToBeAdded.backgroundColor }}></span>
                    </div>

                    <div className='flex flex-row justify-end items-center w-full gap-6'>
                        <button className={`text-md bg-gray-900 text-white font-semibold px-4 py-2 rounded-lg cursor-pointer inline-flex items-center gap-2`} disabled={isSavingProduct} onClick={(e) => { addProduct(e) }}>
                            Save
                            {isSavingProduct && (
                                <div role="status">
                                    <svg aria-hidden="true" className="inline w-4 h-4 text-white animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const EditProduct = ({ product }) => {
    const { refreshProductData } = useProductData();
    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
    const [productToBeEdited, setProductToBeEdited] = useState(product);

    const updateProductState = (event) => {
        const { name, value, type, checked } = event.target;

        setProductToBeEdited(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const saveUpdatedProduct = async (e) => {
        e.preventDefault();

        try {
            setIsUpdatingProduct(true);

            // Make an HTTP POST request to your Netlify function endpoint
            const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/updateProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productToBeEdited),
            });

            if (!response.ok) {
                throw new Error('Failed to update product');
            }

            // After successfully updating, trigger a data refresh
            refreshProductData();

            // Delay the change of the message after 2000 milliseconds (2 seconds)
            const timeoutId = setTimeout(() => {
                setIsUpdatingProduct(false);
            }, 500);

            // Clear the timeout if the component is unmounted or the dependency array changes
            return () => clearTimeout(timeoutId);

        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <div>
            <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-700 mb-1">Edit Product</h6>
            <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Grid Item 1 */}
                <div className='flex flex-col gap-6'>
                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="productName" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Name</label>
                        <input type="text" name="productName" id="productName" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.productName} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="category" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Category</label>
                        <input type="text" name="category" id="category" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.category} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="listPrice" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>List Price (Rs.)</label>
                        <input type="number" name="listPrice" id="listPrice" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.listPrice} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="maximumRetailPrice" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>MRP (Rs.)</label>
                        <input type="number" name="maximumRetailPrice" id="maximumRetailPrice" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.maximumRetailPrice} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="isFeatured" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Is Featured</label>
                        <input type="checkbox" name="isFeatured" id="isFeatured" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' checked={productToBeEdited.isFeatured} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="shortDescription" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Short Description</label>
                        <textarea name="shortDescription" id="shortDescription" cols="30" rows="8" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.shortDescription} onChange={updateProductState} />
                    </div>
                </div>
                {/* Grid Item 2 */}
                <div className='flex flex-col gap-6'>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="videoURL" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Video URL</label>
                        <input type="text" name="videoURL" id="videoURL" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.videoURL} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="flipkartLink" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Flipkart Link</label>
                        <input type="text" name="flipkartLink" id="flipkartLink" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.flipkartLink} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="amazonLink" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Amazon Link</label>
                        <input type="text" name="amazonLink" id="amazonLink" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.amazonLink} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-col shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="description" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Description</label>
                        <textarea name="description" id="description" cols="30" rows="8" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' value={productToBeEdited.description} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="stockAvailable" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Stock Available</label>
                        <input type="checkbox" name="stockAvailable" id="stockAvailable" className='outline-none antialiased font-sans text-sm font-normal leading-normal cursor-pointer bg-transparent' checked={productToBeEdited.stockAvailable} onChange={updateProductState} />
                    </div>

                    <div className='flex flex-row items-center justify-between shadow-md rounded-lg px-4 py-2'>
                        <label htmlFor="backgroundColor" className='text-blue-900 antialiased font-sans text-xs font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500 bg-transparent'>Product Background Color</label>
                        <input type="color" name="backgroundColor" id="backgroundColor" className="appearance-none bg-transparent border-none h-0 w-0 invisible" onChange={updateProductState} />
                        <span className={`h-12 w-12 rounded-full`} style={{ backgroundColor: productToBeEdited.backgroundColor }}></span>
                    </div>

                    <div className='flex flex-row justify-end items-center w-full gap-6'>
                        <button className={`text-md bg-gray-900 text-white font-semibold px-4 py-2 rounded-lg cursor-pointer inline-flex items-center gap-2`} disabled={isUpdatingProduct} onClick={saveUpdatedProduct}>
                            Save
                            {isUpdatingProduct && (
                                <div role="status">
                                    <svg aria-hidden="true" className="inline w-4 h-4 text-white animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default function Inventory() {
    const { products } = useProductData();
    const [activeModal, setActiveModal] = useState(null);

    return (
        <div className="w-full min-h-[530px] relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
            <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
                <div>
                    <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Products</h6>
                    <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-blue-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                        </svg>
                        <strong>{products.length} products</strong> in total
                    </p>
                </div>
                <button aria-expanded="false" aria-haspopup="menu" id=":r5:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button" onClick={() => { setActiveModal(<AddProducts />) }}>
                    <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                        </svg>

                    </span>
                </button>
            </div>
            <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2">
                <table className="w-full min-w-[640px] table-auto">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 w-44 truncate">Product Name</p>
                            </th>
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">List Price</p>
                            </th>
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Category</p>
                            </th>
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Stock Available</p>
                            </th>
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Actions</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={index} className='border-b border-blue-gray-50'>
                                <td className="py-3 px-5">
                                    <div className="flex items-center gap-4">
                                        <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold w-96 truncate">{product.productName}</p>
                                    </div>
                                </td>

                                <td className="py-3 px-5">
                                    <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 truncate">Rs. {product.listPrice}</p>
                                </td>

                                <td className="py-3 px-5">
                                    <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 truncate">{product.category}</p>
                                </td>

                                <td className="py-3 px-5">
                                    <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 truncate">{product.stockAvailable ? 'In Stock' : 'Out Of Stock'}</p>
                                </td>

                                <td className="py-3 px-5 inline-flex items-center gap-4 cursor-pointer" onClick={() => { setActiveModal(<EditProduct product={product} />) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                    </svg>
                                    <p className='block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400'>Edit</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {activeModal && (
                <div className='fixed inset-0 bg-black bg-opacity-60 z-30 flex items-center justify-center'>
                    <div className='w-[90%] h-[90%] xl:w-1/2 xl:h-4/5 bg-white p-2 xl:p-6 rounded-lg relative'>
                        <div className='absolute -right-2 -top-2 w-8 h-8 bg-black inline-flex items-center justify-center text-white rounded-full cursor-pointer font-medium' onClick={() => { setActiveModal(null) }}>X</div>
                        <div className='w-full h-full overflow-y-auto'>
                            {activeModal}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}