import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductData } from '../contexts/ProductDataContextProvider';
import ProductReviews from './ProductReviews';
import { useCart } from '../contexts/CartProvider';
import SEO from '../components/SEO';
import ReturnPolicyIcon from '../assets/Return-Policy.avif';
import QuickShippingIcon from '../assets/Quick-Shipping.avif';
import HappyCustomersIcon from '../assets/Happy-Customers.avif';
import SecurePaymentsIcon from '../assets/Secure-Payments.avif';
import amazonLogo from '../assets/amazon-logo.webp';
import flipkartLogo from '../assets/flipkart-logo.png';

export default function ProductDetails() {
    const navigate = useNavigate();
    const { slug } = useParams();

    const { addToCart } = useCart();
    const { products, isLoadingProducts } = useProductData();
    const [seoElements, setSeoElements] = useState({ title: 'Loading...', description: '', canonicalUrl: 'https://www.rainfire.in/product-details' });

    function createSlug(productName) {
        // Replace spaces and consecutive hyphens with a single hyphen
        const cleanedName = productName
            .replace(/[^a-zA-Z0-9 -]/g, '') // Remove other special characters
            .replace(/ /g, '-')             // Replace spaces with hyphens
            .replace(/-+/g, '-');           // Replace consecutive hyphens with a single hyphen

        // Convert to lowercase and trim any leading/trailing hyphens
        return cleanedName.toLowerCase().replace(/^-+|-+$/g, '');
    }

    // Find the product that matches the slug
    const product = products.find((product) =>
        createSlug(product.productName) === slug
    );

    // State to track the currently selected image
    const [selectedImage, setSelectedImage] = useState(product?.featuredImage);
    useEffect(() => {
        if (!product) return;
        setSelectedImage(product?.featuredImage);
        setSeoElements({ title: product.productName, description: product.description, canonicalUrl: `https://www.rainfire.in/product-details/${createSlug(product.productName)}` })
    }, [product])

    // Click handler to update the selected image
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const [openAccordion, setOpenAccordion] = useState('why-buy-from-us');

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    const reviews = [
        {
            userName: 'Rajesh',
            date: 'Oct 5, 2023',
            rating: 5,
            text: 'Amazing product! I\'m extremely satisfied with my purchase.'
        },
        {
            userName: 'Priya',
            date: 'Oct 10, 2023',
            rating: 4,
            text: 'Great value for the money. I would recommend it to others.'
        },
        {
            userName: 'Amit',
            date: 'Oct 15, 2023',
            rating: 5,
            text: 'I can\'t express how pleased I am with this product.It exceeded my expectations!'
        },
        {
            userName: 'Sneha',
            date: 'Oct 20, 2023',
            rating: 4,
            text: 'I love this product. It\'s made my life so much easier.'
        },
        {
            userName: 'Neha',
            date: 'Oct 25, 2023',
            rating: 5,
            text: 'An excellent product. I would give it 10 stars if I could!'
        }
        // You can add more Indian names with positive reviews here...
    ];

    function extractYouTubeID(url) {
        const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    return (
        <>
            <SEO seoElements={seoElements} />
            <div className='container mx-auto px-4 xl:p-0'>
                {isLoadingProducts && (
                    <div className='flex items-center justify-center min-h-[650px]'>
                        <div role="status">
                            <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}

                {!isLoadingProducts && product && (
                    <div className='w-full'>
                        {/* Product Details Grid */}
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                            {/* Grid Item 1 */}
                            <div className='w-full h-fit lg:sticky lg:top-20 col-span-1 xl:px-6'>
                                <div className='w-fit h-[350px] xl:w-3/4 xl:h-[400px] mx-auto'>
                                    <img src={selectedImage} alt="" className='w-full h-full object-contain' />
                                </div>

                                <div className='mt-6 w-full flex items-center gap-3 justify-start overflow-x-auto hide-scrollbar'>
                                    {product.images.map((image, index) => (
                                        <div key={index} className='w-24 h-24 overflow-hidden rounded-xl cursor-pointer shrink-0 border' onClick={() => handleImageClick(image)}>
                                            <img src={image} alt="" className='w-full h-full object-contain' />
                                        </div>
                                    ))}
                                </div>

                                <div className='mt-8 w-full grid grid-cols-2 xl:grid-cols-4 gap-4 items-center'>
                                    <img src={amazonLogo} alt="" className='cursor-pointer' onClick={() => window.open(product.amazonLink || 'https://www.amazon.in/s?k=rainfire&s=date-desc-rank&qid=1714538782&ref=sr_st_date-desc-rank&ds=v1%3ASPwDkf1cwWOE5%2Ff2KCla6oBCFzULOxmockv%2BwNWfrKE')} />
                                    <img src={flipkartLogo} alt="" className='cursor-pointer' onClick={() => window.open(product.flipkartLink || 'https://www.flipkart.com/search?q=rainfire&otracker=search&otracker1=search&marketplace=FLIPKART&as-show=on&as=off')} />
                                    <button className='p-4 rounded-md col-span-2 order-1 xl:order-3 text-white font-bold bg-blue-600 hover:bg-blue-800 transition-all ease-in-out duration-300 uppercase' onClick={() => { addToCart(product.id); navigate('/cart') }}>Cash On Delivery</button>
                                </div>
                            </div>

                            {/* Grid Item 2 */}
                            <div className='w-full col-span-1 h-fit'>
                                <h2 className='text-base font-medium xl:text-xl xl:font-semibold'>{product.productName}</h2>
                                <div className='mt-4 inline-flex items-center gap-4'>
                                    <span className='text-2xl font-extrabold text-gray-800'>{parseFloat(product.listPrice).toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 0 })}</span>
                                    <span className='text-gray-400 font-medium line-through text-base'>{parseFloat(product.maximumRetailPrice).toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 0 })}</span>
                                    <span className='text-blue-600 font-bold text-base'>
                                        {((parseFloat(product.maximumRetailPrice) - parseFloat(product.listPrice)) / parseFloat(product.maximumRetailPrice) * 100).toFixed(0)}% OFF
                                    </span>
                                </div>
                                <p className='mt-4 text-gray-500 text-sm'>{product.shortDescription}</p>

                                {/* Products Details */}
                                <div className='mt-4 w-full bg-white rounded-3xl shadow-xl  px-6 xl:px-12 py-6 flex flex-col gap-6'>
                                    <div className='accordion'>
                                        <div className='accordion-header w-full flex flex-row items-center justify-between cursor-pointer' onClick={() => { toggleAccordion('product-description') }}>
                                            <div className='flex items-center gap-4'>
                                                <img src="https://cdn.shopify.com/s/files/1/1305/2183/files/Group_19803.png?v=1690547938" className='w-10' alt="Product Icon" />
                                                <span className='text-gray-500 font-medium text-sm xl:text-lg'>Product Description</span>
                                            </div>
                                            <div className={`text-gray-400 text-3xl ${openAccordion === 'product-description' ? 'rotate-45' : ' rotate-0'} transition-all ease-in-out duration-500`}>+</div>
                                        </div>
                                        <div className={`accordion-body text-sm text-gray-500 transition-all duration-300 overflow-hidden ${openAccordion === 'product-description' ? 'mt-4 leading-6 h-auto' : 'h-0'}`} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                            {product.description}
                                        </div>
                                    </div>
                                    <hr />

                                    <div className='accordion'>
                                        <div className='accordion-header w-full flex flex-row items-center justify-between cursor-pointer' onClick={() => { toggleAccordion('product-exchange') }}>
                                            <div className='flex items-center gap-4'>
                                                <img src="https://cdn.shopify.com/s/files/1/1305/2183/files/Group_19801.png?v=1690547904" className='w-10' alt="" />
                                                <span className='text-gray-500 font-medium text-sm xl:text-lg'>Easy Exchange & Returns</span>
                                            </div>
                                            <div className={`text-gray-400 text-3xl ${openAccordion === 'product-exchange' ? 'rotate-45' : ' rotate-0'} transition-all ease-in-out duration-500`}>+</div>
                                        </div>
                                        <div className={`accordion-body text-sm text-gray-500 transition-all duration-300 overflow-hidden ${openAccordion === 'product-exchange' ? 'mt-4 leading-6 h-auto' : 'h-0'}`}>
                                            <ul className='list-disc list-inside'>
                                                <li className="mb-2">We offer 7 Day easy return and exchange on all products.</li>
                                                <li className="mb-2"><a href="https://rainfire.in/return-policy" className='text-blue-600 underline'>Click here</a> to read the full policy</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className='accordion'>
                                        <div className='accordion-header w-full flex flex-row items-center justify-between cursor-pointer' onClick={() => { toggleAccordion('why-buy-from-us') }}>
                                            <div className='flex items-center gap-4'>
                                                <img src="https://cdn.shopify.com/s/files/1/1305/2183/files/Group_19802.png?v=1690547917" className='w-10' alt="" />
                                                <span className='text-gray-500 font-medium text-sm xl:text-lg'>Why Buy From Us?</span>
                                            </div>
                                            <div className={`text-gray-400 text-3xl ${openAccordion === 'why-buy-from-us' ? 'rotate-45' : ' rotate-0'} transition-all ease-in-out duration-500`}>+</div>
                                        </div>
                                        <div className={`accordion-body text-sm text-gray-500 transition-all duration-300 overflow-hidden ${openAccordion === 'why-buy-from-us' ? 'mt-4 leading-6 h-auto' : 'h-0'}`}>
                                            <ul className="list-disc list-inside">
                                                <li className="mb-2 font-medium">7 Days Easy Returns: <span className='font-normal'>Enjoy hassle-free returns within 7 days of purchase, because your satisfaction is our priority.</span></li>
                                                <li className="mb-2 font-medium">Trusted Seller on Amazon and Flipkart: <span className='font-normal'>We're proud to be trusted and registered sellers on Amazon and Flipkart marketplaces, ensuring a secure and reliable shopping experience.</span></li>
                                                <li className="mb-2 font-medium">Registered Trademark and Business: <span className='font-normal'>Rest assured, we're a registered trademark and business, committed to delivering quality products and excellent service every time.</span></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                {/* Rainfire Claims */}
                                <div className='mt-4 w-full grid grid-cols-2 xl:grid-cols-4 gap-6 text-gray-500'>
                                    <div className='p-2 flex flex-col items-center rounded-lg shadow-xl'>
                                        <img src={ReturnPolicyIcon} className='w-16 xl:w-20 mx-auto' alt="" />
                                        <div className='text-center text-xs xl:text-sm'>7 Days</div>
                                        <div className='font-bold text-center text-xs xl:text-sm'>Return</div>
                                    </div>

                                    <div className='p-2 flex flex-col items-center rounded-lg shadow-xl'>
                                        <img src={HappyCustomersIcon} className='w-16 xl:w-20 mx-auto' alt="" />
                                        <div className='text-center text-xs xl:text-sm'>4,32,432+</div>
                                        <div className='font-bold text-center text-xs xl:text-sm'>Customers</div>
                                    </div>

                                    <div className='p-2 flex flex-col items-center rounded-lg shadow-xl'>
                                        <img src={SecurePaymentsIcon} className='w-16 xl:w-20 mx-auto' alt="" />
                                        <div className='text-center text-xs xl:text-sm'>Secured</div>
                                        <div className='font-bold text-center text-xs xl:text-sm'>Payments</div>
                                    </div>

                                    <div className='p-2 flex flex-col items-center rounded-lg shadow-xl'>
                                        <img src={QuickShippingIcon} className='w-16 xl:w-20 mx-auto' alt="" />
                                        <div className='text-center text-xs xl:text-sm'>Quick</div>
                                        <div className='font-bold text-center text-xs xl:text-sm'>Shipping</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* Product Images */}
                        <div className='mt-8 xl:mt-16'>
                            <h2 className='text-2xl font-semibold text-stone-800'>Product Photos</h2>
                            <div className='mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                                {product.videoURL && product.videoURL.length > 0 && (
                                    <div className='player-wrapper col-span-2 h-[500px] bg-black rounded-xl overflow-hidden'>
                                        <iframe
                                            className='youtube-player'
                                            width='100%'
                                            height='100%'
                                            title='Product Video'
                                            src={`https://www.youtube.com/embed/${extractYouTubeID(product.videoURL)}?autoplay=1&mute=1&loop=1&playlist=${extractYouTubeID(product.videoURL)}`}
                                            frameBorder='0'
                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                )}
                                {product.images.map((image, index) => <img key={index} src={image} alt="" className='max-h-[500px] mx-auto' />)}
                            </div>
                        </div>

                        {/* Product Reviews */}
                        <div className='mt-8 xl:mt-16'>
                            <div className='col-span-1 xl:col-span-2 flex flex-col items-center justify-center gap-4'>
                                <h2 className='text-2xl font-medium text-stone-800 text-center'>Customers Reviews</h2>
                                <button className='text-gray-500 border font-light px-4 py-2 rounded-md'>Write A Review</button>
                                <ProductReviews reviews={reviews} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
