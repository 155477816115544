import React from 'react'
import Statistics from './Statistics'
import Inventory from './Inventory'
import Orders from './Orders'

export default function Home() {
  return (
    <div>
      <Statistics />
      <Inventory />
      <div className='mt-6'>
        <Orders />
      </div>
    </div>
  )
}
