import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const ProductDataContext = createContext();

export function useProductData() {
    return useContext(ProductDataContext);
}

export function ProductDataProvider({ children }) {
    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);

    const fetchProductData = useCallback(async () => {
        try {
            setIsLoadingProducts(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/getAllProducts`, {
                method: 'GET'
            });
            if (!response.ok) {
                throw new Error('Failed to fetch product data');
            }
            const productData = await response.json();

            // Sort products by category in ascending order
            const sortedProductData = productData.sort((a, b) => {
                if (a.category < b.category) return -1;
                if (a.category > b.category) return 1;
                return 0;
            });

            setProducts(sortedProductData);
            setIsLoadingProducts(false);
        } catch (error) {
            console.error('Error fetching product data:', error);
            setIsLoadingProducts(false);
        }
    }, []);


    const refreshProductData = useCallback(async () => {
        setIsLoadingProducts(true);
        await fetchProductData();
    }, [fetchProductData]);

    useEffect(() => {
        fetchProductData();
    }, [fetchProductData]);

    return (
        <ProductDataContext.Provider value={{ products, isLoadingProducts, refreshProductData }}>
            {children}
        </ProductDataContext.Provider>
    );
}
