import React from 'react'
import Store from '../pages/Store.js'
import FAQs from '../components/FAQs.js';
import Contact from '../components/Contact.js';
import Slideshow from '../components/Slideshow.js';
import SEO from '../components/SEO.js';
import amazonLogo from '../assets/amazon-logo.webp';
import flipkartLogo from '../assets/flipkart-logo.png';

export default function Home() {
  // Define dynamic SEO data for the home page
  const seoElements = {
    title: 'Rainfire | Premium Electronic Products',
    description: "Welcome to Rainfire - Your destination for Premium Electronic Products. Explore our curated collection of high-quality items and stay ahead of the trends.",
    canonicalUrl: 'https://www.rainfire.in/',
    keywords: "Rainfire, premium products, trending products, online store, e-commerce"
  };

  return (
    <>
      <SEO seoElements={seoElements} />
      <div className='min-h-screen container mx-auto'>
        {/* Hero Section */}
        <div className='mt-4 xl:mt-16 px-4 xl:px-0 container mx-auto flex flex-col items-center justify-center'>
          <h1 className='text-center text-2xl lg:text-6xl font-extrabold text-stone-800'>We're Also Available on</h1>
          <div className='mt-2 xl:mt-4 inline-flex items-center'>
            <img src={amazonLogo} alt="" className='w-32 xl:w-52' />
            <img src={flipkartLogo} alt="" className='w-32 xl:w-52' />
          </div>
          <span className='text-sm font-medium bg-blue-300 text-blue-700 w-fit px-2 py-1 rounded-md'>Cash On Delivery Available</span>
        </div>
        <div className='mt-4 px-4 xl:px-0'>
          <Slideshow />
        </div>
        <div className='mt-8 xl:mt-16'>
          <Store />
        </div>
        <div className='mt-8 xl:mt-16 px-4 xl:px-0'>
          <FAQs />
        </div>
        <div className='mt-8 xl:mt-16 px-4 xl:px-0'>
          <Contact />
        </div>
      </div>
    </>
  );
}