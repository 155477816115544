import React, { useState } from 'react';
import { useProductData } from '../../contexts/ProductDataContextProvider';
import { useOrdersData } from '../contexts/OrdersContext';

export default function Orders() {
    const { products, isLoadingProducts } = useProductData();
    const { orders, isLoadingOrders } = useOrdersData();
    const [orderToBeEdited, setOrderToBeEdited] = useState(null);

    function createSlug(productName) {
        // Replace spaces and consecutive hyphens with a single hyphen
        const cleanedName = productName
            .replace(/[^a-zA-Z0-9 -]/g, '') // Remove other special characters
            .replace(/ /g, '-')             // Replace spaces with hyphens
            .replace(/-+/g, '-');           // Replace consecutive hyphens with a single hyphen

        // Convert to lowercase and trim any leading/trailing hyphens
        return cleanedName.toLowerCase().replace(/^-+|-+$/g, '');
    }

    return (
        <>
            {isLoadingOrders && (
                <div className='flex items-center justify-center h-[400px]'>
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {!isLoadingOrders && orders && orders.length > 0 && (
                <>
                    <div className="w-full min-h-[400px] relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md xl:col-span-2">
                        <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
                            <div>
                                <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Orders</h6>
                                <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-blue-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                                    </svg>
                                    <strong>{orders.length} orders</strong> in total
                                </p>
                            </div>
                            <button aria-expanded="false" aria-haspopup="menu" id=":r5:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                                <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                    </svg>

                                </span>
                            </button>
                        </div>
                        <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2">
                            <table className="w-full min-w-[640px] table-auto">
                                <thead>
                                    <tr>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 truncate">Order ID</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Customer Name</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Email</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Phone</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400 w-44 truncate">Adddress</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Actions</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order, index) => (
                                        <tr key={index} className='border-b border-blue-gray-50'>
                                            <td className="py-3 px-5">
                                                <div className="flex items-center gap-4">
                                                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold truncate">{order?.orderId}</p>
                                                </div>
                                            </td>

                                            <td className="py-3 px-5">
                                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 capitalize truncate">{order?.customerInfo?.fullName}</p>
                                            </td>

                                            <td className="py-3 px-5">
                                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 truncate">{order?.customerInfo?.email}</p>
                                            </td>

                                            <td className="py-3 px-5">
                                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 truncate">{order?.customerInfo?.phone}</p>
                                            </td>

                                            <td className="py-3 px-5">
                                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600 w-44 truncate">{order?.customerInfo?.addressLine1}, {order?.customerInfo?.addressLine2}, {order?.customerInfo?.city}, {order?.customerInfo?.state}, {order?.customerInfo?.zipCode}</p>
                                            </td>

                                            <td className="py-3 px-5 inline-flex items-center gap-4 cursor-pointer" onClick={() => { setOrderToBeEdited(order) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                                </svg>
                                                <p className='block antialiased font-sans text-[11px] font-medium text-blue-gray-400'>Manage</p>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {orderToBeEdited && !isLoadingProducts && (
                        <div className='fixed inset-0 z-50 bg-black bg-opacity-70 flex items-center justify-center p-6'>
                            <div className='mx-auto w-full h-full xl:w-1/2 xl:h-3/4 rounded-3xl bg-white overflow-y-auto p-6'>
                                <div className='flex items-center justify-between'>
                                    <span className='text-sm font-medium'>Manage Order</span>
                                    <span className='w-8 h-8 inline-flex items-center justify-center rounded-full bg-black text-white cursor-pointer' onClick={() => { setOrderToBeEdited(null) }}>X</span>
                                </div>
                                <hr className='mt-4' />
                                {/* Customer Details */}
                                <div className='flex flex-col gap-2 mt-4'>
                                    <p className='text-sm font-medium'>Customer Details</p>
                                    <p className='text-xs capitalize'>Name: {orderToBeEdited.customerInfo.fullName}</p>
                                    <p className='text-xs'>Email: {orderToBeEdited.customerInfo.email}</p>
                                    <p className='text-xs'>Phone: {orderToBeEdited.customerInfo.phone}</p>
                                    <p className='text-xs capitalize'>Shipping To: {orderToBeEdited.customerInfo.addressLine1}, {orderToBeEdited.customerInfo.addressLine2}, {orderToBeEdited.customerInfo.city}, {orderToBeEdited.customerInfo.state}, {orderToBeEdited.customerInfo.zipCode}</p>
                                </div>
                                <hr className='mt-4' />
                                {/* Ordered Items Details */}
                                <div className='flex flex-col gap-2 mt-4'>
                                    <p className='text-sm font-medium'>Order Details</p>
                                    <p className='text-[9px]'>Time: {orderToBeEdited.orderDateTime}</p>
                                    <p className='text-[9px]'>Order ID: {orderToBeEdited.orderId}</p>
                                    <p className='text-[9px]'>Razorpay Payment ID: {orderToBeEdited.paymentId}</p>
                                    <p className='text-[9px]'>Razorpay Signature: {orderToBeEdited.signature}</p>
                                    <table className="w-full table-auto">
                                        <thead>
                                            <tr>
                                                <th className="border-b border-blue-gray-50 py-3 text-left">
                                                    <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">S. No.</p>
                                                </th>
                                                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                                    <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Product ID</p>
                                                </th>
                                                <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                                    <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">Quantity</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderToBeEdited.orderedItems.map((item, index) => (
                                                <tr key={index} className='border-b border-blue-gray-50'>
                                                    <td className="py-3">
                                                        <p className="block antialiased font-sans text-xs font-medium">{index + 1}</p>
                                                    </td>
                                                    <td className="py-3 px-5">
                                                        <p className="block antialiased font-sans text-xs font-medium w-44 truncate cursor-pointer underline" onClick={() => { const p = products.find(p => p.id === item.productID); if (p) window.open(`https://rainfire.in/product-details/${createSlug(p.productName)}`, '_blank'); }}>{item.productID}</p>
                                                    </td>
                                                    <td className="py-3 px-5">
                                                        <p className="block antialiased font-sans text-xs font-medium">{item.quantity}</p>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>

    );
}
