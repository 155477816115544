import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({ seoElements }) {
    const { title, description, canonicalUrl, imageUrl, keywords } = seoElements;

    return (
        <Helmet>
            {/* Basic SEO */}
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
            {imageUrl && <meta name="image" content={imageUrl} />}
            {keywords && <meta name="keywords" content={keywords} />}

            {/* OpenGraph Tags */}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
            {imageUrl && <meta property="og:image" content={imageUrl} />}
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Rainfire" />
            {imageUrl && <meta property="og:image:width" content="1200" />}
            {imageUrl && <meta property="og:image:height" content="630" />}

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            {title && <meta name="twitter:title" content={title} />}
            {description && <meta name="twitter:description" content={description} />}
            {imageUrl && <meta name="twitter:image" content={imageUrl} />}
        </Helmet>
    );
}

SEO.defaultProps = {
    seoElements: {
        title: '',
        description: '',
        canonicalUrl: '',
        imageUrl: '',
        keywords: '',
    }
};

export default SEO;
