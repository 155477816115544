// Import necessary functions from firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import firebaseConfig from "./firebaseConfig";

// Initialize Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth(app);


// Export the auth instance and signInWithEmailAndPassword and signOut functions
export {
  auth,
  signInWithEmailAndPassword,
  signOut, storage, firestore, ref, uploadBytes, getDownloadURL, collection, addDoc, v4, doc, updateDoc
};
