import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg'

// Import Contexts
import { useAuth } from '../../contexts/AuthContextProvider';
import useAdminAuthorization from '../contexts/useAdminAuthorization';

// Import Components
import Home from '../components/Home';
import Inventory from '../components/Inventory';
import Orders from '../components/Orders';

const dashboardViews = [
  { label: 'Home', icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5"><path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" /><path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" /></svg>, component: <Home /> },
  { label: 'Inventory', icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clipRule="evenodd" /><path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" /></svg>, component: <Inventory /> },
  { label: 'Manage Orders', icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z" clipRule="evenodd" /><path fillRule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375Zm9.586 4.594a.75.75 0 0 0-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.116-.062l3-3.75Z" clipRule="evenodd" /></svg>, component: <Orders /> },
  { label: 'Sign Out', icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" /></svg>, component: <Home /> },
];

export default function AdminPanel() {
  const isAdmin = useAdminAuthorization();
  const { authStatus, logout } = useAuth();

  const [sidebarVisible, setSidebarVisibility] = useState(false);
  const [activeDashboardView, setActiveDashboardView] = useState(dashboardViews[0]);

  const toggleSidebar = () => setSidebarVisibility(!sidebarVisible);

  const handleActiveDashboardView = async (item) => {
    if (item.label === 'Sign Out') {
      if (window.confirm('Do you want to log out?')) {
        await logout();
      }
    } else {
      setActiveDashboardView(item);
      if (window.innerWidth <= 640) setSidebarVisibility(false);
    }
  };

  return (isAdmin && !authStatus.isLoading && (
    <div className="w-full h-screen overflow-hidden flex relative box-border max-w-screen-2xl">
      {/* Sidebar */}
      <div className={`h-full bg-white p-4 flex flex-col gap-8 w-4/6 absolute z-30 sm:relative transform -translate-x-full sm:transform-none sm:w-[250px] border-r-[1px] border-r-secondary-900 overflow-y-auto hide-scrollbar transition-all duration-500 ease-in-out ${sidebarVisible ? 'transform-none' : ''}`}>

        <div className='flex gap-4'>
          <div className='w-full flex items-center justify-start gap-4'>
            <img src='https://firebasestorage.googleapis.com/v0/b/rainfire-webapp.appspot.com/o/assets%2Flogo.jpg?alt=media&token=98376859-300d-4775-b978-a2028f7efe2e' alt="User Profile" className="rounded-full w-12 h-12 sm:h-16 sm:w-16 object-cover cursor-pointer" onClick={toggleSidebar} />
            <div className='flex flex-col items-start gap-1'>
              <Link to='/'>
                <img src={logo} alt="" className='w-24' />
              </Link>
              <div className='text-gray-600 text-[10px] truncate'>{isAdmin.email}</div>
            </div>
          </div>
        </div>
        <div className='shrink-0 w-full flex flex-col gap-4'>
          {dashboardViews.map((view, index) => (
            <button key={index} className={`w-full shrink-0 flex gap-4 items-center py-3 px-4 rounded-lg hover:bg-gray-200 text-sm font-medium transition-colors duration-300 ease-in-out ${activeDashboardView === view ? 'bg-gray-200' : ''}`} onClick={() => handleActiveDashboardView(view)}>
              {view.icon}
              <span>{view.label}</span>
            </button>
          ))}
        </div>
        <span className='text-center inline-flex items-center justify-center gap-1 text-[9px]'>
          Made with <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3 text-red-500">
            <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
          </svg> by The Web Navigators
        </span>
      </div>

      {/* Main content */}
      <div className='flex-1 bg-slate-50 h-full p-4 sm:p-6 overflow-y-scroll'>
        <div className='flex items-center justify-between flex-wrap gap-2'>
          <div>
            <span className='text-xs font-semibold text-lime-500'>Dashboard</span>
            <span className='text-xs text-apple-900 font-semibold'> / {activeDashboardView.label}</span>
          </div>
          <div className="flex sm:hidden flex-col gap-1 items-start cursor-pointer" onClick={toggleSidebar}>
            <div className={`bg-black transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-[22px] h-[2px] transform translate-x-0 translate-y-[5px] rotate-45' : 'w-[22px] h-[2px]'}`}></div>
            <div className={` bg-black transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-0 h-0' : 'w-[16px] h-[2px]'}`}></div>
            <div className={` bg-black transition-all ease-in-out duration-200 ${sidebarVisible ? 'w-[22px] h-[2px] transform translate-x-0 -translate-y-[5px] -rotate-45' : 'w-[10px] h-[2px]'}`}></div>
          </div>
        </div>
        <div className='mt-8'>
          {activeDashboardView.component && React.cloneElement(activeDashboardView.component, {

          })}
        </div>
      </div>
    </div>
  ))
}