import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

// Import Contexts
import { AuthProvider } from './contexts/AuthContextProvider';
import { ProductDataProvider } from './contexts/ProductDataContextProvider';
import { CartProvider } from './contexts/CartProvider';
import { OrdersDataProvider } from './admin/contexts/OrdersContext';

// Import Pages
import Home from './pages/Home';
import Store from './pages/Store';
import TrackOrder from './pages/TrackOrder';
import ShippingPolicy from './pages/ShippingPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

// Import Admin
import { AdminPanel } from './admin';
import { AdminLogin } from './admin';

// Import Components
import Header from './components/Header';
import NotFound from './components/NotFound';
import ProductDetails from './components/ProductDetails';
import Cart from './components/Cart';
import Contact from './components/Contact';
import Footer from './components/Footer';

import { HelmetProvider } from 'react-helmet-async';

export default function App() {
  return (
    <HelmetProvider>
      <Router basename="/">
        <Routes>
          <Route path="/*" element={<MainLayout />} />
          <Route path="/admin/*" element={<DashboardLayout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

function DashboardLayout() {
  return (
    <AuthProvider>
      <ProductDataProvider>
        <OrdersDataProvider>
          <Routes>
            <Route path="/" element={<AdminPanel />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </OrdersDataProvider>
      </ProductDataProvider>
    </AuthProvider>
  );
}

function MainLayout() {
  return (
    <ProductDataProvider>
      <CartProvider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store/:category?" element={<Store />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/track-order/:trackingId?" element={<TrackOrder />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product-details/:slug" element={<ProductDetails />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </CartProvider>
    </ProductDataProvider>
  );
}
