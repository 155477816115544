import React, { createContext, useState, useEffect, useContext } from 'react';
import { useProductData } from './ProductDataContextProvider'; // Import the ProductsContext

// Create a context for the shopping cart
const CartContext = createContext();

// Create a custom hook to use the CartContext
export const useCart = () => useContext(CartContext);

// Create a CartProvider component to wrap your application
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        // Load cart from local storage, or initialize to an empty array if not found
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const { products } = useProductData(); // Get products from ProductsContext

    // State for cart visibility
    const [cartVisibility, setCartVisibility] = useState(false);

    // Toggle cart visibility function
    const toggleCartVisibility = () => {
        setCartVisibility(prevVisibility => !prevVisibility);
    };

    // Save cart to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    // Function to add a product to the cart or update if variant exists
    const addToCart = (productID) => {
        const existingItemIndex = cart.findIndex(item => item.productID === productID);

        if (existingItemIndex !== -1) {
            // If the variant exists, update its details (e.g., size)
            const updatedCart = [...cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
            };
            setCart(updatedCart);
            return updatedCart; // Return the updated cart immediately
        } else {
            // If the variant doesn't exist, add it to the cart with quantity 1
            const updatedCart = [...cart, { productID, quantity: 1 }];
            setCart(updatedCart);
            return updatedCart; // Return the updated cart immediately
        }
    };

    // Function to remove a specific variant of a product from the cart
    const removeFromCart = (productID) => {
        const updatedCart = cart.filter((item) => item.productID !== productID);
        setCart(updatedCart);
        return updatedCart; // Return the updated cart immediately
    };

    // Function to clear the cart
    const clearCart = () => {
        setCart([]);
        return []; // Return an empty array as the cart is cleared
    };

    // Function to update the quantity of a product in the cart
    const updateQuantity = (productID, newQuantity) => {
        if (newQuantity <= 0) {
            // Remove the item from the cart if the new quantity is zero or negative
            return removeFromCart(productID);
        }

        const updatedCart = cart.map(item => {
            if (item.productID === productID) {
                return { ...item, quantity: newQuantity };
            }
            return item;
        });

        setCart(updatedCart);
        return updatedCart;
    };

    // Check if products in cart exist in the database, if not remove them
    useEffect(() => {
        if (products.length > 0) {
            setCart(prevCart => {
                const filteredCart = prevCart.filter(item => {
                    return products.some(product => product.id === item.productID);
                });
                return filteredCart;
            });
        }
    }, [products]);

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, updateQuantity, cartVisibility, toggleCartVisibility }}>
            {children}
        </CartContext.Provider>
    );
};