import React from 'react';
import SEO from '../components/SEO.js';

const ReturnPolicy = () => {
  // Define dynamic SEO data for the Return Policy page
  const seoElements = {
    title: 'Return Policy - Rainfire',
    description: "Understand Rainfire's return policy and procedures. Learn how to initiate returns, exchanges, and refunds for your purchases.",
    canonicalUrl: 'https://www.rainfire.in/return-policy'
};


  return (
    <>
      <SEO seoElements={seoElements} />
      <div className="bg-gray-100 py-10">
        <div className="bg-white rounded-lg p-8 shadow-md container mx-auto">
          <h1 className="text-3xl font-semibold mb-4">Return Policy</h1>
          <b className='text-stone-600'>Last updated July 20, 2024</b>
          <br /><br />

          <p className='text-sm text-stone-700'>
            Welcome to the Rainfire Return Policy page. At Rainfire, we are committed to providing you with high-quality products. If you are not entirely satisfied with your purchase, we're here to help.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Refund Eligibility</h2>
          <br />

          <p className='text-sm text-stone-700'>
            To be eligible for a refund, the item must be unused and in the same condition that you received it. It must also be in the original packaging. A video recording from the moment of opening the seal of the product is mandatory for any return to be entertained. You have 7 days from the date the order is delivered to initiate a return.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Returning an Order</h2>
          <br />

          <ol className="list-decimal pl-5">
            <li className="text-sm text-stone-700">Go to the Track Order page on our website.</li>
            <li className="text-sm text-stone-700">Enter your Order ID and hit the "Track Order" button.</li>
            <li className="text-sm text-stone-700">Details regarding your order will be displayed. Click on the "Return" option.</li>
            <li className="text-sm text-stone-700">Follow the instructions to complete the return process.</li>
          </ol>
          <br />

          <h2 className='text-xl font-semibold'>Refund Timeframe</h2>
          <br />

          <p className='text-sm text-stone-700'>
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will be automatically applied to your original payment method within a certain number of days, depending on your payment processor.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Return Conditions</h2>
          <br />

          <p className='text-sm text-stone-700'>
            <strong>Conditions for Return:</strong>
            <ul className="list-disc pl-5">
              <li>Returns must be initiated within 7 days from the date the order is delivered.</li>
              <li>A video recording from the moment of opening the seal of the product is mandatory for any return to be entertained.</li>
            </ul>
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Contact Us</h2>
          <br />

          <p className='text-sm text-stone-700'>
            If you have any questions or concerns about our Return Policy, please contact us at <a href="mailto:support@rainfire.in" className="text-blue-500">support@rainfire.in</a>.
          </p>
        </div>
      </div>

    </>
  );
};

export default ReturnPolicy;
