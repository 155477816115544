import React from 'react';
import { NavLink, Link, useLocation, useParams } from 'react-router-dom';
import { useProductData } from '../contexts/ProductDataContextProvider';
import SEO from '../components/SEO.js';

export default function Store() {
    const location = useLocation();

    // Define dynamic SEO data for the Store page
    const seoElements = {
        title: 'Our Store',
        description: "Explore Rainfire's online store for premium trending products. Browse our curated collection of high-quality items and stay ahead of the trends.",
        canonicalUrl: 'https://www.rainfire.in/store'
    };    

    const { category } = useParams();
    const { products, isLoadingProducts } = useProductData();

    function createSlug(productName) {
        const cleanedName = productName.replace(/[^a-zA-Z0-9 -]/g, '').replace(/ /g, '-').replace(/-+/g, '-');
        return cleanedName.toLowerCase().replace(/^-+|-+$/g, '');
    }

    function renderProduct(product, index) {
        const truncatedName = product.productName.length > 60 ? `${product.productName.slice(0, 60)}...` : product.productName;

        return (
            <Link to={`/product-details/${createSlug(product.productName)}`} key={index} className="bg-gray-100 rounded-3xl p-2 xl:p-4 cursor-pointer">
                <div className='w-full h-44 lg:h-72 bg-white rounded-3xl overflow-hidden'>
                    <img src={product.featuredImage} alt={product.productName} className='p-4 mx-auto w-auto h-full object-contain' />
                </div>
                <div className='h-auto flex flex-col items-start gap-3 mt-4'>
                    <h3 className='font-bold text-sm lg:text-base'>{truncatedName}</h3>
                    <div className='w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:items-center justify-between'>
                        <p className='text-md font-medium text-gray-800'>
                            {parseFloat(product.listPrice).toLocaleString('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </p>
                        <div className='text-sm text-center bg-gray-800 text-white font-medium px-2 py-2 sm:px-3 rounded-md'>
                            Buy Now
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    // Convert slug back to category name
    const decodedCategory = category ? category.replace(/-/g, ' ') : null;

    // Filter products based on the category if it exists
    const filteredProducts = decodedCategory ? products.filter(product => product.category.toLowerCase() === decodedCategory.toLowerCase()) : products;

    return (
        <>
            {location.pathname === '/store' && <SEO seoElements={seoElements} />}
            <div className='min-h-[650px] px-4 xl:px-0'>
                {isLoadingProducts && (
                    <div className='flex items-center justify-center'>
                        <div role="status">
                            <svg aria-hidden="true" className="inline w-4 h-4 text-black animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!isLoadingProducts && filteredProducts && filteredProducts.length > 0 && (
                    <div className='container mx-auto'>
                        <div className='flex flex-col md:flex-row items-center justify-between gap-2'>
                            <div>
                                <h2 className='font-bold text-xl text-slate-800 text-center md:text-start'>Our Store</h2>
                                <p className='text-gray-600 mt-2 text-center md:text-start'>Browse the best of our favorite sale styles and brands</p>
                            </div>
                            <div>
                                <NavLink className="font-bold text-slate-800 inline-flex items-center gap-2 transition-transform transform hover:scale-110" to="/store">
                                    Browse All Products
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clipRule="evenodd" />
                                    </svg>
                                </NavLink>
                            </div>
                        </div>

                        <div className='mt-6'>
                            <div className=" w-full flex items-center gap-4 overflow-x-auto hide-scrollbar">
                                {Array.from(new Set(products.map(product => product.category))).map(category => (
                                    <NavLink key={category} to={`/store/${createSlug(category)}`}
                                        className="shrink-0 px-4 py-3 border rounded-3xl text-gray-600 hover:text-slate-800 transition duration-300"
                                        activeclassname="font-bold text-slate-800">
                                        {category}
                                    </NavLink>
                                ))}
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                            {filteredProducts.map((product, index) => renderProduct(product, index))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
