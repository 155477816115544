import React from 'react';
import SEO from '../components/SEO.js';

const ShippingPolicy = () => {
  // Define dynamic SEO data for the Shipping Policy page
  const seoElements = {
    title: 'Shipping Policy - Rainfire',
    description: "Discover Rainfire's shipping policies and procedures. Learn about shipping rates, delivery times, and tracking information for your orders.",
    canonicalUrl: 'https://www.rainfire.in/shipping-policy'
};


  return (
    <>
      <SEO seoElements={seoElements} />
      <div className='bg-gray-100 py-10'>
        <div className="bg-white rounded-lg p-8 shadow-md container mx-auto">
          <h1 className="text-3xl font-semibold mb-4">Shipping Policy</h1>
          <b className='text-stone-600'>Last updated July 20, 2024</b>
          <br /><br />

          <p className='text-sm text-stone-700'>
            Welcome to Rainfire's Shipping Policy. Here, we provide detailed information about our shipping procedures and policies to ensure that you have a seamless shopping experience with us. Our commitment is to deliver your orders safely and promptly while providing transparency throughout the shipping process.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Free Shipping</h2>
          <br />

          <p className='text-sm text-stone-700'>
            We offer free shipping all over India. There are no additional charges for standard shipping on any of our products.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Delivery Time</h2>
          <br />

          <p className='text-sm text-stone-700'>
            At Rainfire, we understand the importance of timely delivery. Our estimated delivery time is between 5 to 7 days from the date of purchase for orders within India. Please note that while we strive to meet these delivery estimates, unforeseen circumstances such as weather conditions or shipping carrier delays may impact actual delivery times.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Order Processing</h2>
          <br />

          <p className='text-sm text-stone-700'>
            Your satisfaction is our priority, which is why we strive to process and ship your orders promptly. Upon placing an order, it will typically be processed and shipped within 1-2 business days. You will receive a confirmation email with tracking information once your order has been shipped. Please note that orders placed on weekends or holidays will be processed on the next business day. In the event of any delays in processing your order, we will notify you via email to keep you informed throughout the process.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Tracking Your Order</h2>
          <br />

          <p className='text-sm text-stone-700'>
            We believe in transparency and empowerment. Once your order has been shipped, you will receive a tracking number via email. This tracking number allows you to monitor the status of your delivery through our website or the carrier's website. Please allow 24-48 hours for the tracking information to update once your order has been shipped. You can rely on this tracking information to stay informed about the whereabouts of your package until it reaches its destination.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Shipping Restrictions</h2>
          <br />

          <p className='text-sm text-stone-700'>
            While we aim to serve our customers nationwide, there are certain shipping restrictions that we must adhere to. If you have any questions about shipping restrictions or need assistance with your order, please don't hesitate to contact our customer support team for further assistance.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Lost or Damaged Packages</h2>
          <br />

          <p className='text-sm text-stone-700'>
            We understand the frustration that comes with lost or damaged packages. In the rare event that your package is lost or damaged during transit, please contact our customer support team immediately. We will work closely with the shipping carrier to resolve the issue and ensure that you receive a replacement or refund for your order. To expedite the process, please retain the original packaging and documentation as proof of damage for insurance claims purposes. Your satisfaction is our top priority, and we are committed to resolving any issues promptly and efficiently.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Change of Address</h2>
          <br />

          <p className='text-sm text-stone-700'>
            We understand that plans may change, and you may need to update your shipping address after placing an order. If you need to change the shipping address for your order, please contact our customer support team as soon as possible. While we will do our best to accommodate your request, please note that we cannot guarantee changes to the shipping address once the order has been processed and shipped. However, we will work with you to explore available options and ensure that your order reaches you in a timely manner.
          </p>
          <br />

          <h2 className='text-xl font-semibold'>Contact Us</h2>
          <br />

          <p className='text-sm text-stone-700'>
            Your satisfaction is our priority, and we are here to assist you every step of the way. If you have any questions or concerns about your order or our shipping policy, please feel free to contact our customer support team at <a href="mailto:support@rainfire.in" className="text-blue-500">support@rainfire.in</a>. Our dedicated team is available to assist you Monday through Friday, from 9am to 5pm IST. We value your feedback and strive to provide you with the best possible shopping experience. Thank you for choosing Rainfire!
          </p>
        </div>
      </div>

    </>
  );
};

export default ShippingPolicy;
