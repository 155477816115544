import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductData } from '../contexts/ProductDataContextProvider';

const Slide = ({ product, index, navigate, createSlug }) => (
    <div className={`w-full shrink-0 grid grid-cols-1 md:grid-cols-2 items-center justify-between`} onClick={() => navigate(`/product-details/${createSlug(product.productName)}`)}>
        <div className="w-full flex flex-col items-start justify-center gap-4">
            <h2 className="text-xl sm:text-3xl font-extrabold text-slate-900">{product.productName}</h2>
            <p className='text-gray-800'>{product.shortDescription}</p>
            <button className='text-lg bg-gray-900 text-white font-bold p-3 sm:p-4 rounded-lg'>
                Buy Now {parseFloat(product.listPrice).toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </button>
        </div>
        <div className="w-full h-[360px] flex items-center justify-center">
            <img src={product.featuredImage} alt={`Product ${index + 1}`} className="h-full w-auto object-contain rounded-lg" loading='lazy' />
        </div>
    </div>
);

const SlideButton = ({ onClick, direction }) => (
    <button onClick={onClick} className={`absolute ${direction === 'left' ? 'left-4 bottom-4' : 'right-4 bottom-4'} bg-black bg-opacity-30 hover:bg-opacity-80 transition-all ease-in-out duration-300 text-white w-12 h-12 inline-flex items-center justify-center rounded-full`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d={direction === 'left' ? "M15.75 19.5 8.25 12l7.5-7.5" : "m8.25 4.5 7.5 7.5-7.5 7.5"} />
        </svg>
    </button>
);

const DotIndicator = ({ active, onClick }) => (
    <span
        onClick={onClick}
        className={`h-2 w-2 cursor-pointer rounded-full ${active ? 'bg-white' : 'bg-gray-400'}`}
    ></span>
);

const Slideshow = () => {
    const { products } = useProductData();
    const featuredProducts = products.filter(product => product.isFeatured);
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % featuredProducts.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(slideInterval);
    }, [featuredProducts.length]);

    const previousSlide = () => setCurrentSlide((prevSlide) => (prevSlide - 1 + featuredProducts.length) % featuredProducts.length);

    const nextSlide = useCallback(() => setCurrentSlide((prevSlide) => (prevSlide + 1) % featuredProducts.length), [featuredProducts.length]);

    const handleDotClick = (index) => setCurrentSlide(index);

    const createSlug = (productName) => (
        productName.replace(/[^a-zA-Z0-9 -]/g, '').replace(/ /g, '-').replace(/-+/g, '-').toLowerCase().replace(/^-+|-+$/g, '')
    );

    return (products && products.length > 0 && (
        <div className='xl:w-[90%] mx-auto relative rounded-2xl overflow-hidden p-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
            <div className="overflow-hidden">
                <div className='text-sm font-medium bg-blue-300 text-blue-700 w-fit px-2 py-1 rounded-md'>Best Selling</div>
                <div className="mt-4 xl:mt-0 w-full flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                    {featuredProducts.map((product, index) => (
                        <Slide key={index} product={product} index={index} navigate={navigate} createSlug={createSlug} />
                    ))}
                </div>
            </div>
            <div className="w-fit mx-auto flex items-start justify-center gap-2 bg-black px-5 py-3 rounded-full">
                {featuredProducts.map((_, index) => (
                    <DotIndicator key={index} active={index === currentSlide} onClick={() => handleDotClick(index)} />
                ))}
            </div>
            <SlideButton onClick={previousSlide} direction="left" />
            <SlideButton onClick={nextSlide} direction="right" />
            <div className='w-full h-4'></div>
        </div>
    ));
};

export default Slideshow;
