import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContextProvider';

const useAdminAuthorization = () => {
    const { currentUser, authStatus } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authStatus.isLoading) {
            if (!currentUser) {
                // User is not authenticated, redirect to login page
                navigate('/admin/login', { replace: true });
            } else if (currentUser.userRole !== 'admin') {
                // User is authenticated but not an admin, redirect to unauthorized page or homepage
                navigate('/unauthorized', { replace: true });
            }
        }
    }, [currentUser, authStatus, navigate]);

    return currentUser;
};

export default useAdminAuthorization;
