import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a context for orders
const OrdersDataContext = createContext();

export function useOrdersData() {
  return useContext(OrdersDataContext);
}

// Create a provider component
export const OrdersDataProvider = ({ children }) => {
  // State to hold the orders
  const [orders, setOrders] = useState([]);
  
  // State to indicate if orders are being loaded
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  
  // State to hold error if any
  const [error, setError] = useState(null);

  // Function to fetch orders from the endpoint
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/.netlify/functions/getAllOrders`);
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const data = await response.json();
      setOrders(data); // Update the orders state with fetched data
      setIsLoadingOrders(false); // Set loading state to false
    } catch (error) {
      console.error(error);
      setError('Failed to fetch orders'); // Set error state in case of error
      setIsLoadingOrders(false); // Set loading state to false in case of error
    }
  };

  // Fetch orders when the component mounts
  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <OrdersDataContext.Provider value={{ orders, isLoadingOrders, error }}>
      {children}
    </OrdersDataContext.Provider>
  );
};
