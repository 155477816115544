import React from 'react';
import { NavLink, Link } from 'react-router-dom'; // Import NavLink and Link components
import logo from '../assets/logo.svg';

export default function Footer() {
  return (
    <footer className="container mx-auto px-6 xl:px-0 bg-transparent mt-24">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 py-8">
        {/* Left Grid */}
        <div className="flex flex-col md:items-start md:gap-2">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-36 mx-auto md:mx-0" />
          </Link>
          <p className="text-gray-600 text-center md:text-left">
            Elevate Your Tech, Empower Your Style.
          </p>
        </div>

        {/* Right Grid */}
        <div>
          <table className="w-full">
            <thead>
              <tr>
                <th className='text-left'>Quick Links</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='text-sm text-slate-800'>
              <tr>
                <td className='text-left py-2'>
                  <NavLink to="/">Home</NavLink>
                </td>
                <td className="text-right py-2">
                  <NavLink to="/contact">Contact</NavLink>
                </td>
              </tr>
              <tr>
                <td className='text-left py-2'>
                  <NavLink to="/shipping-policy">Shipping Policy</NavLink>
                </td>
                <td className="text-right py-2">
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </td>
              </tr>
              <tr>
                <td className="text-left py-2">
                  <NavLink to="/return-policy">Return Policy</NavLink>
                </td>
                <td className='text-right py-2'>
                  <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <p className="mt-8 text-slate-800 text-center md:text-end">
        © Copyright 2023 | Made by{' '}
        <a href="https://www.upwork.com/freelancers/~012d6eb926a1f7a86a" target="_blank" rel="noopener noreferrer" className='text-blue-600'>Jatin Dahiya</a> &amp;
        <a href="https://thewebnavigators.com/" target="_blank" rel="noopener noreferrer" className='text-blue-600'> The Web Navigators</a>
      </p>
    </footer>
  );
}
